import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ModuleType,
  TGenericGroup,
  TGenericQStruct,
  TSubmoduleDiscriminators,
} from '@dominion/interfaces';
import { CommonModule } from '@angular/common';
import { QuestionPipe } from '../shared/pipes/question.pipe';
import { PromptPipe } from '../shared/pipes/prompt.pipe';
import { QuestionDataPipe } from '../shared/pipes/question-data.pipe';
import { SharedModule } from '../shared/shared.module';
import { ModuleTypePipe } from '../shared/pipes/module-type.pipe';
import { SubmodulePhasePipe } from '../shared/pipes/submodule-phase.pipe';
import { FormsModule } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { IconCloseComponent } from '../icons/icon-close.component';
import { IconChevronDownComponent } from '../icons/icon-chevron-down.component';
import { IconChevronRightComponent } from '../icons/icon-chevron-right.component';
import { IconChevronsDownUpComponent } from '../icons/icon-chevrons-down-up.component';
import { IconChevronsUpDownComponent } from '../icons/icon-chevrons-up-down.component';

export const SLIDE_ANIMATION_MS = 200;

export interface Question extends TGenericQStruct<string> {}
export interface QuestionGroup extends TGenericGroup<string> {
  questions: { [key: string]: Question };
}
export interface FilteredQuestionGroup
  extends Omit<QuestionGroup, 'questions'> {
  questions: Question[];
}
export interface SummaryData {
  groups: QuestionGroup[];
  moduleType?: ModuleType;
  submodulePhase?: TSubmoduleDiscriminators;
  submoduleData: any;
}

@Component({
  selector: 'dominion-discovery-summary',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ModuleTypePipe,
    SubmodulePhasePipe,
    PromptPipe,
    QuestionDataPipe,
    IconCloseComponent,
    IconChevronDownComponent,
    IconChevronRightComponent,
    IconChevronsDownUpComponent,
    IconChevronsUpDownComponent,
  ],
  animations: [
    trigger('slideOver', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate(
          `${SLIDE_ANIMATION_MS}ms ease-in-out`,
          style({ transform: 'translateX(100%)' }),
        ),
      ]),
    ]),
  ],
  providers: [PromptPipe, QuestionPipe, QuestionDataPipe],
  templateUrl: './discovery-summary.component.html',
})
export class DiscoverySummaryComponent implements OnChanges {
  @Input() summaryData: SummaryData;

  @ViewChild('dialog') dialogEl: ElementRef<HTMLDialogElement>;

  searchQuery = '';
  dialogOpen = false;
  showAllResponses = true;
  groupsExpanded = true;
  filteredGroups: FilteredQuestionGroup[] = [];

  constructor(
    private promptPipe: PromptPipe,
    private questionPipe: QuestionPipe,
    private questionDataPipe: QuestionDataPipe,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['summaryData'] && !changes['summaryData'].firstChange) {
      this.updateFilteredGroups();
    }
  }

  private updateFilteredGroups() {
    if (!this.summaryData?.groups?.length) {
      this.filteredGroups = [];
      return;
    }

    try {
      this.filteredGroups = this.summaryData.groups
        .map((group) => {
          const questions = this.questionPipe.transform(group.questions);
          if (!questions?.length) {
            return null;
          }

          return {
            ...group,
            questions: this.getFilteredQuestions(questions),
          };
        })
        .filter(
          (group): group is FilteredQuestionGroup =>
            group !== null && group.questions.length > 0,
        );
    } catch (error) {
      console.error('Error filtering questions:', error);
      this.filteredGroups = [];
    }
  }

  setSearchQuery(query: string): void {
    this.searchQuery = query;
    this.updateFilteredGroups();
  }

  setShowAllResponses(show: boolean): void {
    this.showAllResponses = show;
    this.updateFilteredGroups();
  }

  resetFilters(): void {
    this.searchQuery = '';
    this.showAllResponses = true;
    this.updateFilteredGroups();
  }

  open(): void {
    this.searchQuery = '';
    this.showAllResponses = true;
    this.groupsExpanded = true;
    this.dialogOpen = true;
    this.dialogEl.nativeElement.showModal();
  }

  close(): void {
    this.dialogOpen = false;
    setTimeout(() => {
      this.dialogEl.nativeElement.close();
    }, SLIDE_ANIMATION_MS);
  }

  toggleAllGroups(): void {
    this.groupsExpanded = !this.groupsExpanded;
    requestAnimationFrame(() => {
      document
        .querySelectorAll<HTMLDetailsElement>('details')
        .forEach((detail) => (detail.open = this.groupsExpanded));
    });
  }

  private getFilteredQuestions(questions: Question[]): Question[] {
    const query = this.searchQuery.toLowerCase();

    return questions.filter((question) => {
      const response = this.getResponseText(question).toLowerCase();
      if (!this.showAllResponses && !response) {
        return false;
      }

      if (query) {
        const prompt = this.getPromptText(question).toLowerCase();
        return prompt.includes(query) || response.includes(query);
      }

      return true;
    });
  }

  private getPromptText(question: Question): string {
    return this.promptPipe.transform(question.prompt) ?? '';
  }

  private getResponseText(question: Question): string {
    return (
      this.questionDataPipe.transform(
        question,
        this.summaryData.submoduleData,
      ) ?? ''
    );
  }
}
