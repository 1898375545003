import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import { IDiscoveryDataProperty, ResponseOptions } from '@dominion/interfaces';
import { SharedModule } from '../../shared/shared.module';
import { DiscoveryNextBtnDirective } from '../../shared/directives/discovery-next-btn.directive';
import { ModalComponent } from '../../shared/modal/modal.component';
import { LogComponent } from '../../log/log.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseInputComponent } from '../base-input/base-input.component';
import { AddInstanceButtonComponent } from '../shared-controls/add-instance-button.component';
import { RemoveInstanceButtonComponent } from '../shared-controls/remove-instance-button.component';

@Component({
  selector: 'dominion-input-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    DiscoveryNextBtnDirective,
    ReactiveFormsModule,
    LogComponent,
    AddInstanceButtonComponent,
    RemoveInstanceButtonComponent,
  ],
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.css'],
})
export class InputDropdownComponent
  extends BaseInputComponent
  implements ResponseInput
{
  @Input({ required: true }) override data: IDiscoveryDataProperty<unknown>;
  @Input({ required: true }) override options: ResponseOptions[];
  @Input() override limit? = 1;

  @Output() response: EventEmitter<unknown> = new EventEmitter<unknown>();

  @ViewChild('logModal') logModal: ModalComponent;

  get log() {
    return this.data?.log ? this.data.log : [];
  }

  select(option: ResponseOptions, index: number) {
    this.instances.at(index).setValue(option);
  }
}
