import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseLog, ResponseOptions } from '@dominion/interfaces';

type View = 'all' | 'updates' | 'confirms';
type Category = 'initial' | 'update' | 'confirm';
type ResponseLogWithMeta = ResponseLog & {
  category?: Category;
  formattedValue?: string;
  previousFormattedValue?: string;
};

@Component({
  selector: 'dominion-log',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './log.component.html',
})
export class LogComponent implements OnChanges, OnInit {
  @Input({ required: true }) logs: ResponseLog[] = [];
  @Input() options?: ResponseOptions[] = [];

  views: View[] = ['all', 'updates', 'confirms'];
  selectedView: View = 'all';
  categorizedLogs: ResponseLogWithMeta[] = [];
  filteredLogs: ResponseLogWithMeta[] = [];

  constructor() {}

  ngOnInit() {
    this.updateFilteredLogs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['logs'] && !changes['logs'].firstChange) {
      this.updateFilteredLogs();
    }
  }

  processValue(value: ResponseLog['value']): string | undefined {
    if (value === null || value === undefined) {
      return undefined;
    }

    if (this.options) {
      const optionLabel = this.options.find((option) => option.value === (value?.value ?? value))
        ?.label;
      if (optionLabel) {
        return optionLabel;
      }
    }

    if (typeof value === 'string') {
      return value;
    }

    if (Array.isArray(value)) {
      return this.processArrayValue(value);
    }

    if (typeof value === 'object') {
      return this.processObjectValue(value);
    }

    return undefined;
  }

  private processObjectValue(
    value: Record<string, unknown>,
  ): string | undefined {
    // Handle month-day input
    if (
      typeof value['month'] === 'string' &&
      typeof value['day'] === 'number'
    ) {
      const capitalizedMonth =
        value['month'][0].toUpperCase() + value['month'].slice(1);
      return `${capitalizedMonth} ${value['day']}`;
    }

    return undefined;
  }

  private processArrayValue(value: any[]): string | undefined {
    return value
      .map((item) => {
        // Multiple instances of text, dropdown, etc.
        if (typeof item === 'string') {
          return item;
        }

        // Dropdown
        if (typeof item.value === 'string' && typeof item.label === 'string') {
          return item.label;
        }

        // File uploads
        if (
          typeof item === 'object' &&
          item.dateUploaded !== undefined &&
          typeof item.name === 'string'
        ) {
          return item.name;
        }
      })
      .join(', ');
  }

  setSelectedView(view: View) {
    if (this.selectedView !== view) {
      this.selectedView = view;
      this.filteredLogs = this.applyViewFilter(this.categorizedLogs);
    }
  }

  private updateFilteredLogs() {
    if (this.logs.length === 0) {
      this.filteredLogs = [];
      return;
    }

    this.categorizedLogs = this.categorizeLogs();
    this.filteredLogs = this.applyViewFilter(this.categorizedLogs);
  }

  private categorizeLogs(): ResponseLogWithMeta[] {
    return this.logs.map((log, index, logs) => {
      const category = this.determineCategory(log, index, logs);
      const formattedValue = this.processValue(log.value);
      const previousLog = logs[index + 1];
      const previousFormattedValue = previousLog
        ? this.processValue(previousLog.value)
        : undefined;

      return {
        ...log,
        category,
        formattedValue,
        previousFormattedValue:
          index < logs.length - 1 ? previousFormattedValue : undefined,
      };
    });
  }

  private determineCategory(
    log: ResponseLog,
    index: number,
    logs: ResponseLog[],
  ): Category {
    if (index === logs.length - 1) {
      return 'initial';
    }

    const previousLog = logs[index + 1];
    return JSON.stringify(log.value) === JSON.stringify(previousLog.value)
      ? 'confirm'
      : 'update';
  }

  private applyViewFilter(logs: ResponseLogWithMeta[]): ResponseLogWithMeta[] {
    switch (this.selectedView) {
      case 'updates':
        return logs.filter((log) => log.category === 'update');
      case 'confirms':
        return logs.filter((log) => log.category === 'confirm');
      case 'all':
      default:
        return logs;
    }
  }
}
