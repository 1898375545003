import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {
  ResponseOptions,
  TDiscoveryDataPartial,
  TGenericQStruct,
} from '@dominion/interfaces';

const UNKNOWN_RESPONSE_TEXT = '[Unknown Response]';

function getMatchingOptionLabel(
  value: string | number,
  options: ResponseOptions[],
) {
  return (
    options.find((option) => option.value === value)?.label ||
    UNKNOWN_RESPONSE_TEXT
  );
}

@Pipe({
  name: 'questionDataPipe',
  standalone: true,
})
export class QuestionDataPipe implements PipeTransform {
  transform(
    value: TGenericQStruct<string>,
    data: TDiscoveryDataPartial,
  ): string | null {
    if (!data) {
      return null;
    }

    const questionData = (data as any)[value.questionKey];

    if (questionData === undefined || questionData.value === null) {
      return null;
    }

    switch (value.componentType) {
      case 'text':
      case 'textarea':
      case 'time':
        if (Array.isArray(questionData.value)) {
          return questionData.value.join(', ');
        }
        return questionData.value;
      case 'dropdown':
      case 'select':
      case 'select-list':
        if (Array.isArray(questionData.value)) {
          return questionData.value
            .map((v: { label: string; value: any }) => v.label)
            .join(', ');
        }
        return getMatchingOptionLabel(
          questionData.value?.value ?? questionData.value,
          value.options,
        ).toString();
      case 'address':
        const { street, city, state, zip } = questionData.value || {};
        return `${street || ''} ${city || ''}, ${state || ''} ${zip || ''}`;
      case 'date':
        return new DatePipe('short').transform(questionData.value) || '';
      case 'month-day':
        const { month, day } = questionData.value || {};
        return `${month || ''} ${day || ''}`;
      case 'file':
        return (
          questionData.value
            .map((file: { name: string }) => `File: ${file.name}`)
            .join('\n') || 'No File Uploaded'
        );
      case 'vendor':
        if (
          typeof questionData.value === 'object' &&
          questionData.value !== null &&
          'vendorId' in questionData.value
        ) {
          if (
            'appName' in questionData.value &&
            'providerName' in questionData.value &&
            questionData.value.vendorId !== null
          ) {
            return `${questionData.value.providerName}: ${questionData.value.appName}`;
          } else if (questionData.value.vendorId === null) {
            return 'None';
          } else {
            return 'Vendor Selected';
          }
        }
        return null;
      case 'contact':
        return questionData.value ? 'Contact Provided' : 'No Contact Provided';
      default:
        return UNKNOWN_RESPONSE_TEXT;
    }
  }
}
