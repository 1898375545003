<div class="flex flex-1 flex-row justify-center">
  <div class="flex w-full max-w-[500px] flex-col gap-4 px-9">
    <div [formGroup]="form" data-testid="editable-text-active">
      <div formArrayName="instances" class="flex flex-col gap-2">
        @for (
          control of instances.controls;
          track control;
          let index = $index
        ) {
          <div class="flex items-center">
            <div
              dominionPopoverHost
              class="focus-within:ring-dms-light-green flex flex-1 flex-row rounded border border-solid border-gray-300 ring-offset-1 focus-within:ring"
            >
              @if (inputPrefix) {
                <div
                  class="flex items-center rounded-l border-r border-gray-300 bg-gray-100 px-2 text-sm font-medium text-gray-500 antialiased"
                >
                  {{ inputPrefix }}
                </div>
              }

              <!-- TEXT INPUT -->
              <input
                dominionAutofocus
                [enableAutofocus]="isCurrentQuestion"
                dominionPopoverTarget
                #inputEl
                type="text"
                [formControlName]="index"
                class="flex flex-1 flex-row rounded p-2 text-center outline-none"
                (input)="resetErrors()"
                (blur)="checkErrors()"
                [attr.data-testid]="'editable-text-input-' + index"
                [attr.aria-invalid]="instances.controls[index].invalid"
              />

              @if (inputSuffix) {
                <div
                  class="flex items-center rounded-r border-l border-gray-300 bg-gray-100 px-2 text-sm font-medium text-gray-500 antialiased"
                >
                  {{ inputSuffix }}
                </div>
              }

              <div
                dominionPopover
                class="flex flex-col gap-2 text-sm font-normal text-red-600"
                data-testid="editable-text-popover"
              >
                @if (form.invalid) {
                  <p>{{ errorMessage }}</p>
                }
                @if (serverErrMsg) {
                  <p>{{ serverErrMsg }}</p>
                }
              </div>
            </div>

            @if (canRemoveInstances) {
              <dominion-remove-instance-button
                (onClick)="removeInstance(index)"
                [index]="index"
              ></dominion-remove-instance-button>
            }
          </div>
        }
      </div>
    </div>

    @if (limit) {
      <dominion-add-instance-button
        (onClick)="addInstance()"
        [disabled]="!canAddInstance"
      ></dominion-add-instance-button>
    }

    <div class="mt-8 flex items-center">
      @if (log.length > 0) {
        <div>
          <button
            type="button"
            (click)="logModal.open()"
            class="group flex items-center gap-1 transition"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="text-gray-400"
            >
              <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M12 7v5l4 2" />
            </svg>
            <span class="text-sm text-gray-500 transition group-hover:underline"
              >History</span
            >
          </button>
        </div>
      }

      <div class="flex-1"></div>

      <button
        dominionDiscoveryNextBtn
        type="submit"
        (click)="save()"
        [ngClass]="{ disabled: form.invalid }"
      >
        Next
      </button>
    </div>
  </div>
</div>

<dominion-modal #logModal title="Response History">
  <dominion-log body class="h-[400px]" [logs]="log"></dominion-log>
</dominion-modal>
