import { ResourceAuthStrategy } from './authorization.interfaces';

export const systemAuthorizations = {
  'create:company': [
    {
      type: 'dominion:admin',
    },
  ],
  'update:company': [
    {
      type: 'dominion:projectmanager',
    },
  ],
  'update:dominion:user': [
    {
      type: 'dominion:user',
      context: {
        super: 'super',
        admin: 'super',
        projectmanager: 'admin',
        deploymentmanager: 'admin',
        deploymenttechnician: 'admin',
        viewer: 'admin',
      },
    },
  ],
  'delete:dominion:user': [
    {
      type: 'dominion:user',
      context: {
        super: 'super',
        admin: 'super',
        projectmanager: 'admin',
        deploymentmanager: 'admin',
        deploymenttechnician: 'admin',
        viewer: 'admin',
      },
    },
  ],
  'update:company:user': [
    {
      type: 'dominion:projectmanager',
    },
    {
      type: 'company:user',
      context: {
        super: null,
        admin: 'super',
        manager: 'admin',
      },
    },
  ],
  'delete:company:user': [
    {
      type: 'dominion:projectmanager',
    },
    {
      type: 'company:user',
      context: {
        super: null,
        admin: 'super',
        manager: 'admin',
      },
    },
  ],
  'update:company:completion': [
    {
      type: 'dominion:any',
    },
  ],
} satisfies Record<string, ResourceAuthStrategy<'dominion' | 'company'>[]>;

export type SystemActions = keyof typeof systemAuthorizations;
