import { ICompany, ICoreModule } from '@dominion/interfaces';
import { Types } from 'mongoose';

export const companyFakes: ICompany[] = [
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e256') as any,
    name: 'Dominion Motor Company',
    address: {
      street: '1515 S Federal Hwy',
      line2: 'Suite 406',
      city: 'Boca Raton',
      state: 'FL',
      zip: '33432',
    },
    dms: 'cdk',
    contractExpirationDate: new Date('2023-12-31'),
    preferredInstallDate: new Date('2023-12-31'),
    users: [],
    hubspotId: '1000',
    dateCreated: new Date('2023-11-01'),
    goLiveDate: new Date('2023-12-31'),
    createdBy: 'hubspot',
    parentCompany: null,
    childCompanies: [],
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e257') as any,
    name: 'Trademark Chevrolet',
    address: {
      street: '2000 S Union Street',
      city: 'Norfolk',
      state: 'VA',
      zip: '22332',
    },
    dms: 'reynolds',
    contractExpirationDate: new Date('2023-10-14'),
    preferredInstallDate: null,
    hubspotId: '1001',
    dateCreated: new Date('2023-11-02'),
    createdBy: 'hubspot',
    users: [],
    goLiveDate: new Date('2023-12-31'),
    parentCompany: null,
    childCompanies: [],
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e258') as any,
    name: 'Team Chevrolet',
    address: {
      street: '1856 W US Hwy 30',
      city: 'Valparaiso',
      state: 'IN',
      zip: '46385',
    },
    dms: 'autosoft',
    contractExpirationDate: new Date('2024-03-19'),
    preferredInstallDate: new Date('2025-04-13'),
    hubspotId: '1002',
    goLiveDate: new Date('2023-12-31'),
    dateCreated: new Date('2023-11-02'),
    createdBy: 'hubspot',
    users: [],
    parentCompany: null,
    childCompanies: [],
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e259') as any,
    name: 'Bosak Chevrolet Buick GMC',
    address: {
      street: '9911 W 300 N',
      city: 'Michigan City',
      state: 'IN',
      zip: '46360',
    },
    dms: 'dealertrack',
    contractExpirationDate: new Date('2023-12-21'),
    preferredInstallDate: new Date('2025-11-19'),
    hubspotId: '1003',
    dateCreated: new Date('2023-11-02'),
    createdBy: 'hubspot',
    parentCompany: null,
    users: [],
    goLiveDate: new Date('2023-12-31'),
    childCompanies: [],
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e260') as any,
    name: 'Downtown Hyundai',
    address: {
      street: '1512 Broadway',
      city: 'Nashville',
      state: 'FL',
      zip: '37203',
    },
    dms: 'automate',
    contractExpirationDate: new Date('2024-09-10'),
    preferredInstallDate: new Date('2024-12-14'),
    hubspotId: '1004',
    dateCreated: new Date('2023-11-02'),
    users: [],
    createdBy: 'hubspot',
    parentCompany: null,
    goLiveDate: new Date('2023-12-31'),
    childCompanies: [],
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e261') as any,
    name: 'South Dade Kia of Miami',
    address: {
      street: '17120 S Dixie Hwy',
      city: 'Miami',
      state: 'FL',
      zip: '33157',
    },
    dms: 'dealerbuilt',
    contractExpirationDate: new Date('2023-12-31'),
    preferredInstallDate: null,
    hubspotId: '1005',
    dateCreated: new Date('2023-11-02'),
    createdBy: 'hubspot',
    users: [],
    parentCompany: null,
    goLiveDate: new Date('2023-12-31'),
    childCompanies: [],
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e262') as any,
    name: 'Phil Long Ford of Motor City',
    address: {
      street: '1212 Motor City Dr',
      city: 'Colorado Springs',
      state: 'CO',
      zip: '80905',
    },
    dms: 'adam',
    contractExpirationDate: new Date('2024-06-31'),
    preferredInstallDate: null,
    hubspotId: '1006',
    dateCreated: new Date('2023-11-02'),
    createdBy: 'hubspot',
    users: [],
    goLiveDate: new Date('2023-12-31'),
    parentCompany: null,
    childCompanies: [],
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e263') as any,
    name: 'Capital City Honda',
    address: {
      street: '3630 Fulton Ave',
      city: 'Sacramento',
      state: 'CA',
      zip: '95821',
    },
    dms: 'tekion',
    contractExpirationDate: new Date('2023-08-31'),
    preferredInstallDate: new Date('2025-10-14'),
    hubspotId: '1007',
    users: [],
    dateCreated: new Date('2023-11-02'),
    createdBy: 'hubspot',
    parentCompany: null,
    goLiveDate: new Date('2023-12-31'),
    childCompanies: [],
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e264') as any,
    name: 'Seattle Jeep',
    address: {
      street: '13733 Aurora Ave N',
      city: 'Seattle',
      state: 'WA',
      zip: '98133',
    },
    dms: 'quorum',
    contractExpirationDate: new Date('2023-12-31'),
    preferredInstallDate: new Date('2023-12-31'),
    hubspotId: '1008',
    dateCreated: new Date('2023-11-02'),
    createdBy: 'hubspot',
    users: [],
    parentCompany: null,
    childCompanies: [],
    goLiveDate: new Date('2023-12-31'),
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e265') as any,
    name: 'Ford of Queens',
    address: {
      street: '139-40 Queens Blvd',
      city: 'Queens',
      state: 'NY',
      zip: '11435',
    },
    dms: 'FakeDMS',
    contractExpirationDate: new Date('2023-09-31'),
    preferredInstallDate: new Date('2023-12-31'),
    hubspotId: '1009',
    dateCreated: new Date('2023-11-02'),
    goLiveDate: new Date('2023-12-31'),
    createdBy: 'hubspot',
    users: [],
    parentCompany: null,
    childCompanies: [],
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e266') as any,
    name: 'City World Ford',
    address: {
      street: '3305 Boston Rd',
      city: 'Bronx',
      state: 'NY',
      zip: '10469',
    },
    dms: 'tekion',
    contractExpirationDate: new Date('2023-04-22'),
    preferredInstallDate: new Date('2025-01-12'),
    goLiveDate: new Date('2023-12-31'),
    hubspotId: '1010',
    users: [],
    dateCreated: new Date('2023-11-02'),
    createdBy: 'hubspot',
    parentCompany: null,
    childCompanies: [],
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e267') as any,
    name: 'Group A Child 2',
    address: {
      street: '1924 N Paulina St',
      city: 'Chicago',
      state: 'IL',
      zip: '60622',
    },
    dms: 'reynolds',
    contractExpirationDate: new Date('2023-12-31'),
    preferredInstallDate: new Date('2023-12-31'),
    hubspotId: '1011',
    dateCreated: new Date('2023-11-02'),
    users: [],
    goLiveDate: new Date('2023-12-31'),
    createdBy: 'hubspot',
    parentCompany: '6481d2adca186ee099a3e269',
    childCompanies: [],
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e268') as any,
    name: 'Group A Child 1',
    address: {
      street: '5033 Lemmon Ave',
      city: 'Dallas',
      state: 'TX',
      zip: '75209',
    },
    dms: 'dealerbuilt',
    contractExpirationDate: new Date('2023-10-10'),
    preferredInstallDate: null,
    hubspotId: '1012',
    users: [],
    goLiveDate: new Date('2023-12-31'),
    dateCreated: new Date('2023-11-02'),
    createdBy: 'hubspot',
    parentCompany: '6481d2adca186ee099a3e269',
    childCompanies: [],
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
  {
    _id: new Types.ObjectId('6481d2adca186ee099a3e269') as any,
    name: 'Group A PARENT',
    address: {
      street: '7500 W Azure Dr',
      city: 'Las Vegas',
      state: 'NV',
      zip: '89130',
    },
    dms: 'dealertrack',
    contractExpirationDate: new Date('2023-12-31'),
    preferredInstallDate: null,
    goLiveDate: new Date('2023-12-31'),
    hubspotId: '1013',
    dateCreated: new Date('2023-11-02'),
    createdBy: 'hubspot',
    users: [],
    parentCompany: null,
    childCompanies: ['6481d2adca186ee099a3e268', '6481d2adca186ee099a3e267'],
    modules: [] as unknown as Types.DocumentArray<ICoreModule>,
    invitedUsers: [],
    internalAssigned: {
      projectmanager: null,
      deploymentmanager: null,
      deploymenttechnician: null,
    },
    customVendors: [],
    standardVendors: [],
    vendorAuthorizationSnapshots: [],
    documents: [],
  },
];
