import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import {
  IDiscoveryDataProperty,
  ResponseValidation,
} from '@dominion/interfaces';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PopoverHostDirective } from '../../shared/directives/popover-host.directive';
import { HttpErrorResponse } from '@angular/common/http';
import { DiscoveryNextBtnDirective } from '../../shared/directives/discovery-next-btn.directive';
import { PopoverDirective } from '../../shared/directives/popover.directive';
import { PopoverTargetDirective } from '../../shared/directives/popover-target.directive';
import { ModalComponent } from '../../shared/modal/modal.component';
import { LogComponent } from '../../log/log.component';
import { SharedModule } from '../../shared/shared.module';
import { AutofocusDirective } from '../../shared/directives/autofocus.directive';
import { ValidationService } from '../../services/input-validation.service';
import { FormInputComponent } from '../../form-input/form-input.component';

@Component({
  selector: 'dominion-input-time',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    DiscoveryNextBtnDirective,
    PopoverHostDirective,
    PopoverDirective,
    PopoverTargetDirective,
    LogComponent,
    AutofocusDirective,
    FormInputComponent,
  ],
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.css'],
})
export class InputTimeComponent implements ResponseInput, OnChanges, OnDestroy {
  @Input({ required: true }) data: IDiscoveryDataProperty<string>;
  @Input() validation?: ResponseValidation[] = [];
  @Input() isCurrentQuestion: boolean = false;
  @Output() response = new EventEmitter<string>();

  @ViewChild(PopoverHostDirective) popoverHost: PopoverHostDirective;
  @ViewChild('logModal') logModal: ModalComponent;
  @ViewChild('hourInputEl') hourInputEl: ElementRef<HTMLInputElement>;
  @ViewChild('minuteInputEl') minuteInputEl: ElementRef<HTMLInputElement>;
  @ViewChild('meridiemInputEl') meridiemInputEl: ElementRef<HTMLButtonElement>;

  readonly HOUR_PATTERN = /^([1-9]|1[0-2])$/;
  readonly MINUTE_PATTERN = /^[0-5]?[0-9]$/;
  readonly MERIDIEM_PATTERN = /^(AM|PM)$/;

  form: FormGroup;
  clientErrMsg?: string;
  serverErrMsg?: string;

  get log() {
    return this.data?.log ?? [];
  }

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private validationService: ValidationService,
  ) {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      hour: ['', this.getHourValidators()],
      minute: ['', this.getMinuteValidators()],
      meridiem: ['AM', this.getMeridiemValidators()],
    });
  }

  private getHourValidators() {
    return [
      Validators.required,
      Validators.min(1),
      Validators.max(12),
      Validators.pattern(this.HOUR_PATTERN),
    ];
  }

  private getMinuteValidators() {
    return [
      Validators.required,
      Validators.min(0),
      Validators.max(59),
      Validators.pattern(this.MINUTE_PATTERN),
    ];
  }

  private getMeridiemValidators() {
    return [Validators.required, Validators.pattern(this.MERIDIEM_PATTERN)];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.setInitialValue();
    }
    if (changes['validation'] && this.validation) {
      this.applyValidators();
    }
  }

  ngOnDestroy(): void {
    this.popoverHost?.hide();
  }

  private setInitialValue(): void {
    if (!this.data?.value) return;

    const [time, meridiem] = this.data.value.split(' ');
    const [hour, minute] = time.split(':');

    this.form.patchValue({
      hour: hour,
      minute: minute,
      meridiem: meridiem || 'AM',
    });

    this.cdr.detectChanges();
  }

  applyValidators(): void {
    const customValidators = this.validationService.getValidators(
      this.validation ?? [],
    );

    this.form
      .get('hour')
      ?.setValidators([...this.getHourValidators(), ...customValidators]);

    this.form
      .get('minute')
      ?.setValidators([...this.getMinuteValidators(), ...customValidators]);

    this.form.updateValueAndValidity();
  }

  onKeydown(event: KeyboardEvent, type: 'hour' | 'minute' | 'meridiem'): void {
    if (type === 'hour' && event.key === ':') {
      event.preventDefault();
      this.minuteInputEl.nativeElement.focus();
      return;
    }

    if (
      ['minute', 'meridiem'].includes(type) &&
      ['a', 'p'].includes(event.key.toLowerCase())
    ) {
      event.preventDefault();
      this.handleMeridiemKey(event.key.toLowerCase());
    }
  }

  private handleMeridiemKey(key: string): void {
    const currentMeridiem = this.form.get('meridiem')?.value;
    if (
      (key === 'a' && currentMeridiem === 'PM') ||
      (key === 'p' && currentMeridiem === 'AM')
    ) {
      this.toggleMeridiem();
    }
  }

  onInput(type: 'hour' | 'minute'): void {
    this.resetErr();

    const value =
      type === 'hour'
        ? this.hourInputEl.nativeElement.value
        : this.minuteInputEl.nativeElement.value;

    const nextElement =
      type === 'hour' ? this.minuteInputEl : this.meridiemInputEl;

    const { min, max } =
      type === 'hour' ? { min: 1, max: 12 } : { min: 0, max: 59 };

    this.autoAdvance(value, nextElement, min, max);
  }

  private autoAdvance(
    value: string,
    nextElement: ElementRef,
    min: number,
    max: number,
  ) {
    const numValue = parseInt(value, 10);
    if (value.length === 2 && numValue >= min && numValue <= max) {
      nextElement.nativeElement.focus();
    }
  }

  toggleMeridiem() {
    const current = this.form.get('meridiem')?.value;
    this.form.patchValue({
      meridiem: current === 'AM' ? 'PM' : 'AM',
    });
    this.cdr.detectChanges();
  }

  save() {
    this.resetErr();

    if (!this.form.valid) {
      this.clientErrMsg = 'Please enter a valid time.';
      this.popoverHost?.show();
      return;
    }

    this.response.emit(this.formatTime());
  }

  private formatTime(): string {
    const hour = this.form.get('hour')?.value || '';
    const minuteValue = this.form.get('minute')?.value;
    const minute = minuteValue
      ? String(parseInt(minuteValue, 10)).padStart(2, '0')
      : '00';
    const meridiem = this.form.get('meridiem')?.value || 'AM';
    return `${hour}:${minute} ${meridiem}`;
  }

  checkErr() {
    if (this.form.invalid) {
      this.clientErrMsg = 'Please enter a valid time.';
      this.popoverHost.show();
    }
  }

  handleErr(err: HttpErrorResponse): void {
    this.serverErrMsg = err.error.message;
    this.popoverHost.show();
  }

  handleSuccess(): void {
    return;
  }

  resetErr() {
    this.clientErrMsg = undefined;
    this.serverErrMsg = undefined;
    this.popoverHost.hide();
  }
}
