import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponseInput } from '../input.interface';
import {
  ResponseValidation,
  IDiscoveryDataProperty,
} from '@dominion/interfaces';
import { ReactiveFormsModule } from '@angular/forms';
import { PopoverHostDirective } from '../../shared/directives/popover-host.directive';
import { DiscoveryNextBtnDirective } from '../../shared/directives/discovery-next-btn.directive';
import { PopoverDirective } from '../../shared/directives/popover.directive';
import { PopoverTargetDirective } from '../../shared/directives/popover-target.directive';
import { ModalComponent } from '../../shared/modal/modal.component';
import { LogComponent } from '../../log/log.component';
import { SharedModule } from '../../shared/shared.module';
import { AutofocusDirective } from '../../shared/directives/autofocus.directive';
import { BaseInputComponent } from '../base-input/base-input.component';
import { AddInstanceButtonComponent } from '../shared-controls/add-instance-button.component';
import { RemoveInstanceButtonComponent } from '../shared-controls/remove-instance-button.component';

@Component({
  selector: 'dominion-input-text',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    DiscoveryNextBtnDirective,
    PopoverHostDirective,
    PopoverDirective,
    PopoverTargetDirective,
    LogComponent,
    AutofocusDirective,
    AddInstanceButtonComponent,
    RemoveInstanceButtonComponent,
  ],
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.css'],
})
export class InputTextComponent
  extends BaseInputComponent
  implements ResponseInput
{
  @Input({ required: true }) override data: IDiscoveryDataProperty<
    string | string[]
  >;
  @Input() override validation?: ResponseValidation[] = [];
  @Input() inputPrefix?: string;
  @Input() inputSuffix?: string;
  @Input() isCurrentQuestion: boolean = false;
  @Input() override limit? = 1;

  @ViewChild('logModal') logModal: ModalComponent;

  @Output() override response: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  get log() {
    return this.data?.log ? this.data.log : [];
  }
}
